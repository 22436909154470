import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { StorageService } from './storage.service';
import { NotificationService } from './notification.service';
import { AppService } from '../../app.service';
import { ModalService } from './modal.service';
import { I18nService } from './i18n.service';
@Injectable()
export class AuthGuardUser implements CanActivate {

  constructor(
    private appService: AppService,
    private router: Router,
    private storageService: StorageService,
    private modalService: ModalService,
    private notification: NotificationService,
    private i18n: I18nService
  ) { }

  async canActivate() {
    if (!this.storageService.getStorage().token) {
      this.storageService.clearStorage();
      this.modalService.closeModal();
      this.router.navigate(['/login']);
      this.appService.showLoading.next(false);
      return false;
    } else if (this.storageService.getExpiresDate() < new Date()) {
      this.storageService.clearStorage();
      this.modalService.closeModal();
      this.router.navigate(['/login']);
      this.appService.showLoading.next(false);
      return false;
    }
    if (!this.storageService.getSelectedOrganization()) {
      this.notification.info(await this.i18n.searchTranslation('selecioneOrganizacao'),
        await this.i18n.searchTranslation('selecioneOrganizacaoAntes'));
      this.router.navigate(['/home']);
      this.appService.showLoading.next(false);
      return false;
    }

    return true;
  }
}
