import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ImageSearchService {

  constructor(
    private http: HttpClient,
    private storage: StorageService
  ) { }

  getImage(idOrganization: number, imageIdentification: number) {
    return this.http.get(environment.apiUrl + `LogoParametrization/GetByIdentificationAndOrg/${idOrganization}/${imageIdentification}`).pipe(first()).toPromise();
  }

  getImageObservable(idOrganization: number, imageIdentification: number): Observable<any> {
    return this.http.get(environment.apiUrl + `LogoParametrization/GetByIdentificationAndOrg/${idOrganization}/${imageIdentification}`);
  }
}
