import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { StorageService } from './shared/services/storage.service';

@Injectable({ providedIn: 'root' })

export class AppService {

  constructor(
    private storage: StorageService
  ) { }

  lastRoute = new BehaviorSubject<string>(null);
  currentRoute = new BehaviorSubject<string>(null);
  showLoading = new BehaviorSubject<boolean>(null);
  showNav = new BehaviorSubject<boolean>(true);
  windowSize = new BehaviorSubject<number>(670);

  isEuroMotorsUser(): boolean {
    var user = this.storage.getStorage().user;
    if (user.email.toLowerCase().includes('grupoeuromotors'))
      return true;
    else
      return false;
  }

  isDowUser(): boolean {
    var user = this.storage.getStorage().user;
    var domain = user.email.substring(user.email.lastIndexOf("@") + 1);
    if (domain.toLowerCase().includes('dow'))
      return true;
    else
      return false;
  }
}
