import { Component, Injectable, Directive, TemplateRef } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

interface DeleteOptions {
  title: string,
  message: string,
  confirmButton: string,
  cancelButton: string
}

@Injectable()
export class DeleteState {
  options: DeleteOptions;
  modal: NgbModalRef;
  template: TemplateRef<any>;
}

@Injectable()
export class DeleteService {

  constructor(private modalService: NgbModal, private state: DeleteState) { }

  delete(options: DeleteOptions): Promise<any> {
    this.state.options = options;
    this.state.modal = this.modalService.open(this.state.template);
    return this.state.modal.result;
  }
}

@Component({
  selector: 'delete-modal-component',
  template: `
        <div class="modal-header">
            <h4 class="modal-title">{{ options.title }}</h4>
            <button type="button" class="close" aria-label="Fechar" (click)="no()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <span>{{ options.message }}</span>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-danger" (click)="yes()">{{ options.confirmButton }}</button>
            <button type="button" class="btn btn-dark" (click)="no()" ngbAutofocus>{{ options.cancelButton }}</button>
        </div>`
})
export class DeleteModalComponent {

  options: DeleteOptions;

  constructor(private state: DeleteState) {
    this.options = state.options;
  }

  yes() {
    this.state.modal.close('confirmed');
  }

  no() {
    this.state.modal.dismiss('not confirmed');
  }
}

@Directive({
  selector: "ng-template[delete]"
})
export class DeleteDirective {
  constructor(confirmTemplate: TemplateRef<any>, state: DeleteState) {
    state.template = confirmTemplate;
  }
}
