import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';
import { LogoParamEnum } from 'src/app/shared/enums/logo-param.enum';
import { UserProfileEnum } from 'src/app/shared/enums/user-profile.enum';
import { OrganizationLabelsModel, OrganizationLogoModel, UserOrganizationsLoginModel } from 'src/app/shared/models/organization.model';
import { UserLoginModel } from 'src/app/shared/models/user.model';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { StorageService } from 'src/app/shared/services/storage.service';
import { ImageSearchService } from 'src/app/shared/services/image-search.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  labels: OrganizationLabelsModel[];
  image: OrganizationLogoModel;
  user: UserLoginModel;
  systemName: string;
  showNav = this.appService.showNav;
  isRaizen: boolean = false;
  isBenteler: boolean = false;
  appLogo: string;
  organizations: UserOrganizationsLoginModel[];
  constructor(
    private i18n: I18nService,
    private storage: StorageService,
    private imageSearchService: ImageSearchService,
    private appService: AppService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngOnInit(): void {
    this.organizations = this.storage.getStorage().organizations;
    this.labels = this.storage
      .getSelectedOrganization()
      ?.labels?.filter((x) => x.labelKey != 'BEACON_ASSOCIATION');

    this.user = this.storage.getStorage().user;

    this.appService.showNav.subscribe(_ => {
      //console.log('showNav');
      this.isRaizen = this.storage.getSelectedOrganization()?.organizationName?.toLowerCase().startsWith('raizen');
      this.isBenteler = this.storage.getSelectedOrganization()?.organizationName?.toLowerCase().startsWith('benteler');
    });

    this.getImage();
  }

  ngAfterViewChecked() {
    this.isRaizen = this.storage.getSelectedOrganization()?.organizationName?.toLowerCase().startsWith('raizen');
    this.cdRef.detectChanges();
  }

  getImage() {
    var selectedOrganization = this.storage.getSelectedOrganization();
    if (selectedOrganization) {
      this.systemName = selectedOrganization.systemName;
      this.imageSearchService
        .getImageObservable(selectedOrganization.idOrganization, LogoParamEnum.logoHome)
        .subscribe((x: OrganizationLogoModel) => {
          if (x == null) {
            if (selectedOrganization?.systemName.toLowerCase() == "tgagro") {
              this.appLogo = 'assets/tg_agro_negativo.png';
            } else {
              this.appLogo = 'assets/tg_auto_principal.svg';
            }
          } else {
            this.image = x;
          }
        });
    } else {
      this.appLogo = '';
    }
  }

  getHomeLogoByOrg() {
    if (this.image)
      return 'data:image/jpg;base64,' + this.image.image;
    else
      if(this.appLogo)
        return this.appLogo;
      else
        return "assets/raizen.jpg";
  }

  hasColumnKey() {
    if(this.organizations.length==0)
      if (this.labels && this.labels.find((x) => x && x.isKeyColumn)) return true;
      else return false;
    else
      return true;
  }

  isSuperUser() {
    if (this.user && this.user.idProfile == UserProfileEnum.SuperUser)
      return true;
    else return false;
  }

  isUser() {
    if (this.user && this.user.idProfile == UserProfileEnum.User) return true;
    else return false;
  }

  isVisitor() {
    if (this.user && this.user.idProfile == UserProfileEnum.Visitor) return true;
    else return false;
  }

}
