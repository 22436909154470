import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import * as jwt_decode from 'jwt-decode';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../../environments/environment';
import { StorageDataModel } from '../models/storage.model';
import { UserLoginModel } from '../models/user.model';
import { UserGridModel } from '../models/user-grids.model';
import { UserOrganizationsLoginModel, InstanceModel } from '../models/organization.model';

@Injectable({ providedIn: 'root' })
export class StorageService {

  encryptSecretKey: string = 'rV|"x@K7!(]J=#EmRD!Lupmi7#<]qVzbtDXQ]BLx"-m@LhXuf_R"&j($(_ecO*`';

  constructor(private http: HttpClient) { }

  encryptData(data) {
    try {
      return CryptoJS.AES.encrypt(JSON.stringify(data), this.encryptSecretKey.toString()).toString();
    } catch (e) {
      console.log(e);
    }
  }

  decryptData(data) {
    try {
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data, this.encryptSecretKey.toString());
        if (bytes.toString()) {
          return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        return data;
      }
      return null;
    } catch (e) {
      console.log(e);
    }
  }

  getStorage(): StorageDataModel {
    var storageData: StorageDataModel = {
      token: this.decryptData(localStorage.getItem(environment.keys.token)),
      // refreshToken: this.decryptData(localStorage.getItem(environment.keys.refresh_token)),
      expires: new Date(this.decryptData(localStorage.getItem(environment.keys.expires))),
      authTime: new Date(this.decryptData(localStorage.getItem(environment.keys.authTime))),
      user: this.decryptData(localStorage.getItem(environment.keys.user)) as UserLoginModel,
      env: this.decryptData(localStorage.getItem(environment.keys.env)),
      organizations: this.decryptData(localStorage.getItem(environment.keys.userOrganizations)) as UserOrganizationsLoginModel[]
    }
    return storageData;
  }

  getUser(): UserLoginModel {
    return this.decryptData(localStorage.getItem(environment.keys.user)) as UserLoginModel;
  }

  setUser(user: UserLoginModel) {
    localStorage.setItem(environment.keys.user, this.encryptData(user));
  }

  setStorage(token: string, env: string, organizations: UserOrganizationsLoginModel[]) {
    const claims = jwt_decode(token);
    let expires = claims['exp'];
    let auth_time = claims['nbf'];
    let user: UserLoginModel = {
      id: claims['id'], email: claims['email'], name: claims['name'], profile: claims['profile'], idProfile: claims['idprofile'], authType : claims['authtype']
    };

    try {
      this.clearStorage();
      localStorage.setItem(environment.keys.token, this.encryptData(token));
      // localStorage.setItem(environment.keys.refresh_token, this.encryptData(refresh_token));
      localStorage.setItem(environment.keys.expires, this.encryptData(new Date(expires * 1000).toString()));
      localStorage.setItem(environment.keys.authTime, this.encryptData(new Date(auth_time * 1000).toString()));
      localStorage.setItem(environment.keys.user, this.encryptData(user));
      localStorage.setItem(environment.keys.env, this.encryptData(env));
      localStorage.setItem(environment.keys.userOrganizations, this.encryptData(organizations))
    } catch (e) {
      console.log(e);
    }
  }

  setSelectedOrganization(organization?: UserOrganizationsLoginModel) {
    try {
      localStorage.removeItem(environment.keys.selectedOrganization);
      if (organization) {
        localStorage.setItem(environment.keys.selectedOrganization, this.encryptData(organization));
      }
    } catch (e) {
      console.log(e);
    }
  }

  clearSelectedInstance() {
    localStorage.removeItem(environment.keys.selectedInstance);
  }

  getSelectedOrganization(): UserOrganizationsLoginModel {
    try {
      var organization = localStorage.getItem(environment.keys.selectedOrganization);
      if (organization) {
        //return this.decryptData(organization) as UserOrganizationsLoginModel;
        var decrypted = this.decryptData(organization);
        return decrypted;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  }

  setSelectedInstance(instance?: InstanceModel) {
    try {
      localStorage.removeItem(environment.keys.selectedInstance);
      if (instance) {
        localStorage.setItem(environment.keys.selectedInstance, this.encryptData(instance));
      }
    } catch (e) {
      console.log(e);
    }
  }

  getSelectedInstance(): InstanceModel {
    try {
      var instance = localStorage.getItem(environment.keys.selectedInstance);
      if (instance) {
        return this.decryptData(instance) as InstanceModel;
      } else {
        return null;
      }
    } catch (e) {
      console.log(e);
    }
  }

  refreshStorage(token: string, refresh_token: string, env: string) {
    const claims = jwt_decode(token);
    let expires = claims['exp'];
    try {
      //REMOVE
      localStorage.removeItem(environment.keys.token);
      localStorage.removeItem(environment.keys.refresh_token);
      localStorage.removeItem(environment.keys.expires);
      localStorage.removeItem(environment.keys.env);
      //SETs
      localStorage.setItem(environment.keys.token, this.encryptData(token));
      localStorage.setItem(environment.keys.refresh_token, this.encryptData(refresh_token));
      localStorage.setItem(environment.keys.expires, this.encryptData(new Date(expires * 1000).toString()));
      localStorage.setItem(environment.keys.env, this.encryptData(env));
    } catch (e) {
      console.log(e);
    }
  }

  setEnvironment(env: string) {
    try {
      localStorage.removeItem(environment.keys.env);
      localStorage.setItem(environment.keys.env, this.encryptData(env));
    } catch (e) {
      console.log(e);
    }
  }

  setUserGrid(userGrid: UserGridModel) {
    try {
      var userGrids = this.decryptData(localStorage.getItem(environment.keys.userGrids)) as UserGridModel[];
      if (userGrids) {
        var index = userGrids.findIndex(u => u.gridScreen === userGrid.gridScreen);
        if (index != -1) {
          userGrids.splice(index, 1);
          userGrids.push(userGrid);
        } else {
          userGrids.push(userGrid);
        }
      } else {
        userGrids = [];
        userGrids.push(userGrid);
      }
      localStorage.setItem(environment.keys.userGrids, this.encryptData(userGrids));
    } catch (e) {
      console.log(e);
    }
  }

  getUserGrids() {
    try {
      var userGrids = this.decryptData(localStorage.getItem(environment.keys.userGrids)) as UserGridModel[];
      return userGrids;
    } catch (e) {
      console.log(e);
    }
  }

  getUserGrid(gridScreen: string) {
    try {
      var userGrids = this.decryptData(localStorage.getItem(environment.keys.userGrids)) as UserGridModel[];
      if (userGrids)
        return userGrids.find(u => u.gridScreen === gridScreen);
      return null;
    } catch (e) {
      console.log(e);
    }
  }

  clearStorage() {
    localStorage.clear();
  }

  getExpiresDate(): Date {
    return new Date(this.decryptData(localStorage.getItem(environment.keys.expires)));
  }

  hasData(): boolean {
    return !!localStorage.getItem(environment.keys.env) || !!localStorage.getItem(environment.keys.token);
  }

  setLanguage(language: string) {
    try {
      localStorage.removeItem(environment.keys.language);
      localStorage.setItem(environment.keys.language, this.encryptData(language));
    } catch (e) {
      console.log(e);
    }
  }

  getLanguage() {
    try {
      var language = this.decryptData(localStorage.getItem(environment.keys.language));
      if (!language) {
        language = 'pt';
        localStorage.setItem(environment.keys.language, this.encryptData(language));
        return language;
      }
      return language;
    } catch (e) {
      console.log(e);
    }
  }

  setUserTrackingViewOption(value: string) {
    try {
      localStorage.removeItem(environment.keys.trackingViewOption);
      localStorage.setItem(environment.keys.trackingViewOption, this.encryptData(value));
    } catch (e) {
      console.log(e);
    }
  }

  getUserTrackingViewOption() {
    try {
      return this.decryptData(localStorage.getItem(environment.keys.trackingViewOption));
    } catch (e) {
      console.log(e);
    }
  }
}
