import { Injectable } from '@angular/core';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })

export class ApiErrorsTranslateService {

  constructor(
    private i18n: I18nService
  ) { }

  async translateError(error: string) {
    switch (error.toLowerCase()) {
      case 'Missing property name'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoNome');
      case 'Missing property auth key'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoAuthKey');
      case 'Organization with that name already registered'.toLowerCase():
        return await this.i18n.searchTranslation('nomeJaRegistrado');
      case 'Missing property id'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoId');
      case 'Organization not found'.toLowerCase():
        return await this.i18n.searchTranslation('organizacaoNaoEncontrada');
      case 'Place with that name already registered'.toLowerCase():
        return await this.i18n.searchTranslation('nomeJaRegistrado');
      case 'Missing property id organization'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoOrganizacao');
      case 'Place not found'.toLowerCase():
        return await this.i18n.searchTranslation('localNaoEncontrado');
      case 'Missing property organization'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoOrganizacao');
      case 'Gateway already registered'.toLowerCase():
        return await this.i18n.searchTranslation('gatewayJaRegistrado');
      case 'Missing property gateway'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoGateway');
      case 'Gateway not found'.toLowerCase():
        return await this.i18n.searchTranslation('gatewayNaoEncontrado');
      case 'Name already registred'.toLowerCase():
        return await this.i18n.searchTranslation('nomeJaRegistrado');
      case 'Beacon already registred'.toLowerCase():
        return await this.i18n.searchTranslation('beaconJaRegistrado');
      case 'Prism not found'.toLowerCase():
        return await this.i18n.searchTranslation('prismaNaoEncontrado');
      case 'Id must be greater than 0'.toLowerCase():
        return await this.i18n.searchTranslation('idMaiorQueZero');
      case 'Name can not be empty'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoNome');
      case 'Name can not be null'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoNome');
      case 'Name must have less than 80 characters'.toLowerCase():
        return await this.i18n.searchTranslation('nomeMenorQue80');
      case 'Beacon must have 12 characters'.toLowerCase():
        return await this.i18n.searchTranslation('beacon12');
      case 'Beacon must have less than 80 characters'.toLowerCase():
        return await this.i18n.searchTranslation('descricaoMenorQue80');
      case 'Prism already related'.toLowerCase():
        return await this.i18n.searchTranslation('prismaRelacionado');
      case 'Missing property prism'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoPrisma');
      case 'Missing property plate'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoPlaca');
      case 'Prism not related'.toLowerCase():
        return await this.i18n.searchTranslation('prismaNaoRelacionado');
      case 'Invalid Credentials'.toLowerCase():
        return await this.i18n.searchTranslation('credenciaisInvalidas');
      case 'Invalid Email'.toLowerCase():
        return await this.i18n.searchTranslation('emailInvalido');
      case 'Missing property email'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoEmail');
      case 'Missing property password'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoSenha');
      case 'Needs to be password or refresh_token'.toLowerCase():
        return await this.i18n.searchTranslation('precisaSerSenhaOuRefreshToken');
      case 'Email already registered'.toLowerCase():
        return await this.i18n.searchTranslation('emailJaRegistrado');
      case 'Missing property profile'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoPerfil');
      case 'The current password does not match'.toLowerCase():
        return await this.i18n.searchTranslation('senhaAtualNaoConfere');
      case 'Invalid verification code'.toLowerCase():
        return await this.i18n.searchTranslation('codigoInvalido');
      case 'Missing property New Password'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoNovaSenha');
      case 'User not found'.toLowerCase():
        return await this.i18n.searchTranslation('usuarioNaoEncontrado');
      case 'Email not registered'.toLowerCase():
        return await this.i18n.searchTranslation('emailNaoRegistrado');
      case 'Missing property user'.toLowerCase():
        return await this.i18n.searchTranslation('usuarioFaltando');
      case 'Plate already registered'.toLowerCase():
        return await this.i18n.searchTranslation('placaJaRegistrada');
      case 'The organization already has more users than that registered'.toLowerCase():
        return await this.i18n.searchTranslation('organizacaoTemMaisUsuarios');
      case 'The organization has reached the limit of registered users'.toLowerCase():
        return await this.i18n.searchTranslation('organizacaoAtingiuLimite');
      case 'User must change password'.toLowerCase():
        return await this.i18n.searchTranslation('troqueASenhaAntesDeProsseguir');
      case 'Missing property label key'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoChaveCampo');
      case 'Missing property label value'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoValorCampo');
      case 'Missing property key column'.toLowerCase():
        return await this.i18n.searchTranslation('faltandoCampoChave');
      case 'Label is already registered'.toLowerCase():
        return await this.i18n.searchTranslation('chaveCampoJaRegistrado');
      case 'Label not found'.toLowerCase():
        return await this.i18n.searchTranslation('campoNaoEncontrado');
    }
  }
}
