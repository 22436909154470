import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './views/main/main.component';
import { LoginComponent } from './views/login/login.component';
import { AuthGuard } from './shared/services/auth-guard.service';
import { AuthGuardUser } from './shared/services/auth-guard-user.service';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./views/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'location',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/location/location.module').then(m => m.LocationModule)
      },
      {
        path: 'gateways',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/gateway/gateway.module').then(m => m.GatewayModule)
      },
      {
        path: 'prisms',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/prisms/prisms.module').then(m => m.PrismsModule)
      },
      {
        path: 'groups',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/groups/groups.module').then(m => m.GroupsModule)
      },
      {
        path: 'places',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/place/place.module').then(m => m.PlaceModule)
      },
      {
        path: 'users',
        canActivate: [AuthGuardUser],
        loadChildren: () => import('./views/user/user.module').then(m => m.UserModule)
      },
      {
        path: 'monitored-prisms',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/monitored-prisms/monitored-prisms.module').then(m => m.MonitoredPrismsModule)
      },
      {
        path: 'change-password',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/change-password/change-password.module').then(m => m.ChangePasswordModule)
      },
      {
        path: 'tracking',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/tracking/tracking.module').then(m => m.TrackingModule)
      },
      {
        path: 'exchange-sets',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/exchange-sets/exchange-sets.module').then(m => m.ExchangeSetsModule)
      },
      {
        path: 'audit',
        canActivate: [AuthGuardUser],
        loadChildren: () => import('./views/audit/audit.module').then(m => m.AuditModule)
      },
      {
        path: 'organization-labels',
        canActivate: [AuthGuardUser],
        loadChildren: () => import('./views/organization-labels/organization-labels.module').then(m => m.OrganizationLabelsModule)
      },
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'bi-dashboard',
        canActivate: [AuthGuard],
        loadChildren: () => import('./views/bi-dashboard/bi-dashboard.module').then(m => m.BIDashboardModule)
      }
    ]
  },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
