import { Component, OnInit, ElementRef, Inject } from '@angular/core';
import { LoginService } from './login.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { LoginModel, LoginResult, SendVerificationCodeModel, ChangePasswordModel } from 'src/app/shared/models/login.models';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { EmailValidator } from '../../shared/validators/email-validator';
import { PasswordValidator } from '../../shared/validators/password-validator';
import { StorageService } from 'src/app/shared/services/storage.service';
import { AppService } from 'src/app/app.service';
import { Router, NavigationEnd } from '@angular/router';
import { faLock, faUser, faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { ApiErrorsTranslateService } from 'src/app/shared/services/api-errors-translate.service';
import { WINDOW } from '../../window_provider';
import { environment } from 'src/environments/environment';
import { UserLoginModel } from 'src/app/shared/models/user.model';
import { UserProfileEnum } from 'src/app/shared/enums/user-profile.enum';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  changePasswordForm: FormGroup;
  forgotPasswordForm: FormGroup;
  faLock = faLock;
  faUser = faUser;
  faGlobeAmericas = faGlobeAmericas;
  email: string;
  language: string;
  forgotPassword: boolean;
  changePassword: boolean;
  changePasswordRequired: boolean = false;
  newPasswordForm: FormGroup;
  IsTGAgro: boolean;
  SSO: boolean;

  constructor(
    private service: LoginService,
    private notification: NotificationService,
    private fb: FormBuilder,
    private storage: StorageService,
    private appService: AppService,
    private router: Router,
    private i18n: I18nService,
    private apiErros: ApiErrorsTranslateService,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {

    var IsTGAuto = this.window.location.origin.toLowerCase().indexOf("tgauto") > 0;
    var IsRaizen = this.window.location.origin.toLowerCase().indexOf("raizen") > 0;
    var IsTaggen = this.window.location.origin.toLowerCase().startsWith("https://taggen.");

    this.SSO = ((!IsTGAuto && ( IsRaizen  || IsTaggen)) || !environment.production);

    this.loginFormSetup();
    this.language = this.i18n.language.value;
    this.IsTGAgro = this.window.location.origin.toLowerCase().indexOf("tgagro") > 0;

  }

  loginFormSetup() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, EmailValidator.emailValidator]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }

  newPassworFormSetup() {
    this.newPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: [{ value: '', disabled: true }, [Validators.required, Validators.minLength(6)]]
    }, {
      validator: PasswordValidator.matchPassword
    });
  }

  changePasswordFormSetup() {
    this.changePasswordForm = this.fb.group({
      code: ['', Validators.required],
      password: ['', [Validators.required, Validators.minLength(6)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(6)]]
    }, {
      validator: PasswordValidator.matchPassword
    });
  }

  forgotPasswordFormSetup() {
    this.forgotPasswordForm = this.fb.group({
      email: ['', [Validators.required, EmailValidator.emailValidator]]
    });
  }

  async authenticate() {
    var loginData = this.loginForm.getRawValue();
    var request: LoginModel = {
      email: loginData['email'],
      password: loginData['password'],
      grantType: 'password'
    };
    await this.service.authenticate(request).then((result: LoginResult) => {
      this.storage.setStorage(result.access_token, result.environment, result.organizations);
      this.appService.showNav.next(true);
      var user = this.storage.getStorage().user;

      if(this.isMonitor(user))
      {
          var organizations = this.storage.getStorage().organizations;
          this.storage.setSelectedOrganization(organizations[0]);
          this.storage.clearSelectedInstance();

          this.storage.setSelectedInstance(organizations[0].instances[0]);

          this.router.navigate(['/dashboard']);
      }
      else
        this.router.navigate(['/home']);

    }).catch(async error => {
      console.log(error);
      if (error.error && Array.isArray(error.error)) {
        if (error.error == 'User must change password') {
          this.changePasswordRequired = true;
          this.newPassworFormSetup();
          this.notification.info(await this.i18n.searchTranslation('atencao'), await this.apiErros.translateError(error.error[0]));
        }
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
    });
  }


  isMonitor(user: UserLoginModel) {
    if (user && user.idProfile == UserProfileEnum.Monitor) 
      return true;
    else 
      return false;
  }
  async sendCode() {
    this.email = this.forgotPasswordForm.controls['email'].value;
    var request: SendVerificationCodeModel = {
      email: this.forgotPasswordForm.controls['email'].value
    };

    await this.service.forgotPassword(request).then(async result => {
      this.forgotPassword = false;
      this.notification.success(await this.i18n.searchTranslation('enviado'),
        await this.i18n.searchTranslation('codigoEnviado'));
      this.changePasswordFormSetup();
      this.changePassword = true;
    }).catch(async error => {
      if (error.error && Array.isArray(error.error)) {
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
      console.log(error);
    });
  }

  async savePassword() {
    var form = this.changePasswordForm.getRawValue();
    var request: ChangePasswordModel = {
      email: this.email,
      verificationCode: form['code'],
      newPassword: form['confirmPassword'],
      grantType: 'forgot_password'
    };
    await this.service.changePassword(request).then(async result => {
      this.notification.success(await this.i18n.searchTranslation('salva'),
        await this.i18n.searchTranslation('novaSenhaSalva'));
      this.changePassword = false;
    }).catch(async error => {
      if (error.error && Array.isArray(error.error)) {
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
      console.log(error);
    });
  }

  forgotPasswordModalOpen(status: boolean) {
    if (status)
      this.forgotPasswordFormSetup();
    this.forgotPassword = status;
  }

  changePasswordModalOpen(status: boolean) {
    if (status) {
      this.email = this.forgotPasswordForm.controls['email'].value;
      this.forgotPassword = status;
      this.changePasswordFormSetup();
    }
    this.forgotPassword = false;
    this.changePassword = status;
  }

  async changeLanguage(language: string, myLangDrop: any) {
    this.i18n.changeLanguage(language);
    this.language = this.i18n.language.value;
    myLangDrop.close();
  }

  async saveNewPassword() {
    var loginData = this.loginForm.getRawValue();
    var form = this.newPasswordForm.getRawValue();
    var request: ChangePasswordModel = {
      email: loginData['email'],
      oldPassword: loginData['password'],
      newPassword: form['passwordConfirmation'],
      grantType: 'new_password'
    };
    await this.service.changePassword(request).then(async result => {
      this.notification.success(await this.i18n.searchTranslation('salva'),
        await this.i18n.searchTranslation('novaSenhaSalva'));
      this.router.navigate(['/home']);
    }).catch(async error => {
      if (error.error && Array.isArray(error.error)) {
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
      console.log(error);
    });
  }

  passwordValidationSize() {
    if (this.newPasswordForm.controls['password'].valid) {
      this.newPasswordForm.controls['confirmPassword'].enable();
    } else {
      this.newPasswordForm.controls['confirmPassword'].disable();
      this.newPasswordForm.controls['confirmPassword'].reset();
    }
  }

  async saveNewPasswordFirstLogin() {
    var form1 = this.newPasswordForm.getRawValue();
    var form2 = this.loginForm.getRawValue();
    var request: ChangePasswordModel = {
      email: form2['email'],
      oldPassword: form2['password'],
      newPassword: form1['confirmPassword'],
      grantType: 'new_password'
    };
    await this.service.changePassword(request).then(async result => {
      this.notification.success(await this.i18n.searchTranslation('salva'),
        await this.i18n.searchTranslation('novaSenhaSalva'));
      this.loginForm.controls['password'].setValue(form1['confirmPassword']);
      this.authenticate();
    }).catch(async error => {
      if (error.error && Array.isArray(error.error)) {
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
      console.log(error);
    });
  }
}
