<h4 *ngIf="isVisitor()" class="text-warning" style="text-transform: uppercase;">{{ 'semAcesso' | translate }}!</h4>

<div *ngIf="!isVisitor()">
  <div class="row" *ngIf="!isRaizen && !isBenteler;else  (isRaizen ? raizen_content : benteler_content)" >
    <div class="mt-4" *ngIf="appLogo!=''">
      <h1>{{('bemVindo' | translate: {'systemName': systemName})}}</h1>
    </div>

    <img style="width:100%;" [src]="getHomeLogoByOrg()" alt="" /> 
  </div>

  <ng-template #raizen_content>
      <div class="mt-4" *ngIf="appLogo!=''">
          <h1>{{('bemVindo' | translate: {'systemName': systemName})}}</h1>
      </div>
      <img style="width:100%;" *ngIf="appLogo!=''"  [src]="getHomeLogoByOrg()" alt=""/>
    
  </ng-template>

  <ng-template #benteler_content>
      <div class="mt-4" *ngIf="appLogo!=''">
          <h1>{{('bemVindo' | translate: {'systemName': systemName})}}</h1>
      </div>

    
      <img _ngcontent-ukq-c5="" src="./assets/welcome_benteler.png" style="height: 75vh;" alt="" />
  </ng-template>

  <div *ngIf="showNav | async">
    <div class="mt-5 row" *ngIf="!hasColumnKey()">
      <h4 *ngIf="isSuperUser()" class="text-warning" style="text-transform: uppercase;">{{ 'semCampoChaveSuperUsuario' | translate }}!</h4>
      <h4 *ngIf="isUser()" class="text-warning" style="text-transform: uppercase;">{{ 'semCampoChaveUsuario' | translate }}!</h4>
    </div>
  </div>
</div>