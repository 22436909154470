<app-loading [statusLoading]></app-loading>
<div class="app-body" *ngIf="!SSO">
  <main class="main d-flex align-items-center">
    <div class="container">
      <div class="row">
        <div class="col-md-9 mx-auto text-center">
          <!-- <h4>TG Auto</h4> -->
        </div>
        <div class="col-md-9 mx-auto mt-2">
          <div class="card-group">
            <div class="card p-4">

         
              <div *ngIf="!changePasswordRequired" class="card-body text-center">
                <form novalidate [formGroup]="loginForm">
                  <!-- <h1>{{ 'entrar' | translate }}</h1> -->
                  <p>{{ 'bemVindoLogin' | translate }}</p>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <fa-icon [icon]="faUser"></fa-icon>
                      </span>
                    </div>
                    <input type="text" class="form-control" placeholder="{{ 'email' | translate }}" required #form
                      formControlName="email">
                  </div>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <fa-icon [icon]="faLock"></fa-icon>
                      </span>
                    </div>
                    <input type="password" class="form-control" placeholder="{{ 'senha' | translate }}" required
                      minlength="6" #password formControlName="password" (keyup.enter)="authenticate()">
                  </div>
                  <div class="row mb-4">
                    <div class="col-12 col-sm-5 text-left">
                      <button type="button" class="btn btn-success px-4 text-white" [disabled]="loginForm.invalid"
                        (click)="authenticate()">
                        {{ 'entrar' | translate }}
                      </button>
                    </div>
                    <div class="col-12 col-sm-7 text-right">
                      <button type="button" class="btn btn-link px-0" (click)="forgotPasswordModalOpen(true)">
                        {{ 'esqueceuSenha' | translate }}?
                      </button>
                    </div>
                    <div class="col-12 mt-2">
                      <div class="nav-item my-auto mr-3" #myLangDrop="ngbDropdown" ngbDropdown autoClose="outside"
                        placement="bottom">
                        <button class="bg-white btn" id="dropdownLang" ngbDropdownToggle>
                          <fa-icon [icon]="faGlobeAmericas" class="fa-lg mr-1"></fa-icon>
                          {{ 'idioma' | translate }}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownLang">
                          <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('pt', myLangDrop)">
                            <img width="26px" height="16px" src="assets/flags/brazil.png" alt="">
                            <span class="align-middle ml-2">Português</span>
                          </button>
                          <hr class="my-1 p-0">
                          <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('en', myLangDrop)">
                            <img width="26px" height="16px" src="assets/flags/usa.png" alt="">
                            <span class="align-middle ml-2">English</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div *ngIf="changePasswordRequired" class="card-body text-center">
                <form novalidate [formGroup]="newPasswordForm">
                  <!-- <h1>{{ 'entrar' | translate }}</h1> -->
                  <p>{{ 'necessarioTrocarASenha' | translate }}</p>
                  <div class="input-group mb-4">
                    <div class="input-group-prepend">
                      <span class="input-group-text">
                        <fa-icon [icon]="faLock"></fa-icon>
                      </span>
                    </div>
                    <input type="password" (keyup)="passwordValidationSize()" class="form-control"
                      placeholder="{{ 'novaSenha' | translate }}" required minlength="6" #password
                      formControlName="password">
                  </div>
                  <div class="form-group row">
                    <!-- <label class="col-12 col-sm-3 mt-2">{{ 'confirmarNovaSenha' | translate }}:</label> -->
                    <div class="col-12">
                      <div class="input-group mb-1">
                        <div class="input-group-prepend">
                          <span class="input-group-text">
                            <fa-icon [icon]="faLock"></fa-icon>
                          </span>
                        </div>
                        <input type="password" class="form-control" placeholder="{{ 'confirmarNovaSenha' | translate }}"
                          required minlength="6" formControlName="confirmPassword">
                      </div>
                      <span class="help-block text-danger"
                        *ngIf="newPasswordForm.controls['password'].value && newPasswordForm.controls['confirmPassword'].value && newPasswordForm.invalid">
                        {{ 'senhaNaoConfere' | translate }}!</span>
                    </div>
                  </div>

                  <div class="row mb-4">
                    <div class="col-12 col-sm-5 text-left">
                      <button type="button" class="btn btn-primary px-4 text-white" [disabled]="newPasswordForm.invalid"
                        (click)="saveNewPasswordFirstLogin()">
                        {{ 'salvar' | translate }}
                      </button>
                    </div>
                    <div class="col-12 col-sm-7 text-right">
                      <button type="button" class="btn btn-secondary px-4 text-white"
                        (click)="changePasswordRequired = false">
                        {{ 'cancelar' | translate }}
                      </button>
                    </div>
                    <!-- <div class="col-12 mt-2">
                      <div class="nav-item my-auto mr-3" #myLangDrop="ngbDropdown" ngbDropdown autoClose="outside"
                        placement="bottom">
                        <button class="bg-white btn" id="dropdownLang" ngbDropdownToggle>
                          <fa-icon [icon]="faGlobeAmericas" class="fa-lg mr-1"></fa-icon>
                          {{ 'idioma' | translate }}
                        </button>
                        <div ngbDropdownMenu aria-labelledby="dropdownLang">
                          <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('pt', myLangDrop)">
                            <img width="26px" height="16px" src="assets/flags/brazil.png" alt="">
                            <span class="align-middle ml-2">Português</span>
                          </button>
                          <hr class="my-1 p-0">
                          <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('en', myLangDrop)">
                            <img width="26px" height="16px" src="assets/flags/usa.png" alt="">
                            <span class="align-middle ml-2">English</span>
                          </button>
                        </div>
                      </div>
                    </div> -->
                  </div>
                </form>
              </div>
            </div>
            <div class="card text-white py-5" style="background-color: #2a353a;">
              <div class="card-body text-center" style="margin-top: 3.5rem;">
                <div class="mb-4">
                  <p>
                    {{ 'semUsuario' | translate }}
                    <a href="mailto:suporte@taggen.com.br">suporte@taggen.com.br</a>
                  </p>
                </div>
                <div class="conatiner row justify-content-center align-items-center">
                  <img src="assets/logo-taggen.png" alt="Powered by Taggen" width="25%">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>
</div>

<p-dialog [(visible)]="forgotPassword" [style]="{width: '600px'}" header="{{ 'esqueciSenha' | translate }}"
  [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <form novalidate [formGroup]="forgotPasswordForm">
      <div class="form-group">
        <label>{{ 'emailCadastrado' | translate }}</label>
        <input type="email" class="form-control" formControlName="email" (keyup.enter)="sendCode(changePasswordModal)"
          ngbAutofocus>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'enviarCodigo' | translate }}" (click)="sendCode(changePasswordModal)"
      [disabled]="forgotPasswordForm.invalid" icon="pi pi-check" class="p-button-raised p-button-success"></button>
    <button pButton pRipple label="{{ 'possuoCodigo' | translate }}" [disabled]="forgotPasswordForm.invalid"
      (click)="changePasswordModalOpen(true)" icon="pi pi-check" class="p-button-raised p-button-primary"></button>
    <button pButton pRipple label="{{'cancelar' | translate}}" icon="pi pi-times"
      class="p-button-raised p-button-secondary" (click)="forgotPasswordModalOpen(false)"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="changePassword" [style]="{width: '600px'}" header="{{ 'alterarSenha' | translate }}"
  [modal]="true" styleClass="p-fluid">
  <ng-template pTemplate="content">
    <form novalidate [formGroup]="changePasswordForm">
      <div class="form-group row">
        <label class="col-12 mt-2" for="code">{{ 'codigo' | translate }}:</label>
        <div class="col-12">
          <input class="form-control" placeholder="" type="text" formControlName="code" autocomplete="off" id="code" value="" >
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12 mt-2" for="password">{{ 'novaSenha' | translate }}:</label>
        <div class="col-12">
          <input class="form-control" placeholder="" type="password" formControlName="password" id="novsenha"  value="" >
        </div>
      </div>
      <div class="form-group row">
        <label class="col-12 mt-2" for="confirmPassword">{{ 'confirmarNovaSenha' | translate }}:</label>
        <div class="col-12">
          <input class="form-control" placeholder="" type="password" formControlName="confirmPassword"
            (keyup.enter)="setPassword()" id="confirmasenha"  value="" >
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template pTemplate="footer">
    <button pButton pRipple label="{{ 'salvar' | translate }}" (click)="savePassword()"
      [disabled]="changePasswordForm.invalid" icon="pi pi-check" class="p-button-raised p-button-success"></button>
    <button pButton pRipple label="{{'cancelar' | translate}}" icon="pi pi-times"
      class="p-button-raised p-button-secondary" (click)="changePasswordModalOpen(false)"></button>
  </ng-template>
</p-dialog>
