import { Component, Inject, OnInit } from '@angular/core';
import { AppService } from './app.service';
import { Router, NavigationStart, NavigationEnd } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { StorageService } from './shared/services/storage.service';
import { WINDOW } from '../app/window_provider';
import { LoginService } from './views/login/login.service';
import { NotificationService } from './shared/services/notification.service';
import { I18nService } from './shared/services/i18n.service';
import { ApiErrorsTranslateService } from './shared/services/api-errors-translate.service';
import { LoginResult } from './shared/models/login.models';
import { environment } from 'src/environments/environment';
import { UserProfileEnum } from './shared/enums/user-profile.enum';
import { UserLoginModel } from './shared/models/user.model';
import { UserService } from './views/user/user.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  constructor(
    private appService: AppService,
    private storage: StorageService,
    private title: Title,
    private router: Router,
    @Inject(WINDOW) private window: Window,
    private login: LoginService,
    private notification: NotificationService,
    private i18n: I18nService,
    private apiErros: ApiErrorsTranslateService
  ) { }

  async ngOnInit() {

    var IsTGAuto = this.window.location.origin.toLowerCase().indexOf("tgauto") > 0;
    var IsRaizen =  this.window.location.origin.toLowerCase().startsWith("https://raizen");
    var IsTaggen = this.window.location.origin.toLowerCase().startsWith("https://taggen.");
    if((!IsTGAuto && ( IsRaizen  || IsTaggen)) || !environment.production)
    {
      var chave = (IsRaizen ? "RAIZEN" :  "TAGGEN" );
      var user = this.storage.getStorage().user;
      if(user == null)
      {
        if(this.window.location.href.toLowerCase().indexOf("/login") == -1)  
        {
          if(this.window.location.href.toLowerCase().indexOf("code") == -1)  
          {
            // var envSettings = environment.auth.filter(p=>p.id == chave);
            // if( envSettings!== undefined && envSettings !== null)
            // {
              // var env= envSettings[0];
              // var uri =  `${env.keys['domain']}oauth2/authorize?client_id=${env.keys['client_id']}&response_type=code&scope=email+openid&redirect_uri=${env.keys['redirect_uri']}`;
               this.GoToSSOUrl('TGAGRO',chave);
            // }
          }
          else
          {
            
            let paramsBlock = this.window.location.href.split("?")[1];
            let params = paramsBlock.split('&').reduce((p, c) => {
                let components = c.split('=');
                p[components[0]] = components[1]
                return p;
            }, new Map<string, string>());

            this.authenticatesso('TGAGRO', params["code"], chave);

            return;
          }
        }
      }
      else
      {
        if(this.isMonitor(user))
          this.router.navigate(['/dashboard']);
        else
          this.router.navigate(['/home']);
      }
    }
    
    this.router.events.subscribe((evt) => {
      var IsTGAgro = this.window.location.origin.toLowerCase().indexOf("tgagro") > 0;
      var org = this.storage.getSelectedOrganization();
      if (org && org.systemName)
        this.title.setTitle(org.systemName);
      else
        this.title.setTitle("Taggen IOT");
      if (evt instanceof NavigationStart) {
        this.appService.showLoading.next(true);
        this.appService.lastRoute.next(this.router.url);
        if (evt.url == '/login')
          this.title.setTitle("Taggen IOT");
      }
      if (evt instanceof NavigationEnd) {
        this.appService.showLoading.next(false);
        this.appService.currentRoute.next(this.router.url);
        if (evt.url == '/login')
          this.title.setTitle("Taggen IOT");
      }
    });
    this.appService.windowSize.next(window.outerHeight);
    window.addEventListener('resize', () => {
      this.appService.windowSize.next(window.outerHeight);
    });


    setInterval(() => {
      var user = this.storage.getStorage().user;
      if(user !== null)
      {
        this.login.HeartBeat().then((logged: UserLoginModel) => {
          if(logged)
          { 
            if(user.idProfile.toString() !== logged.idProfile.toString())
             {
                this.storage.setUser(logged);
                window.location.reload();

             }
          }
          else
          {
            this.storage.clearStorage();
            this.router.navigate(['/']);
            window.location.reload();
          }
        });
      }
    }, 60000);
  }

  async authenticate(application: string, code: string, chave: string) {
    await this.login.authenticatesso(application,code, chave).then((result: LoginResult) => {
      this.storage.setStorage(result.access_token, result.environment, result.organizations);
      this.appService.showNav.next(true);

      var user = this.storage.getStorage().user;

      if(this.isMonitor(user))
        this.router.navigate(['/dashboard']);
      else
        this.router.navigate(['/home']);

    }).catch(async error => {
      console.log(error);
      if (error.error && Array.isArray(error.error)) {
        if (error.error == 'User must change password') {
          this.notification.info(await this.i18n.searchTranslation('atencao'), await this.apiErros.translateError(error.error[0]));
        }
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
    });
  }


  isMonitor(user: UserLoginModel) {
    if (user && user.idProfile == UserProfileEnum.Monitor) 
      return true;
    else 
      return false;
  }
  

  async authenticatesso(application: string, code: any, chave: string) {
    await this.login.identifysso(application,code, chave).then((result: any) => {

      this.authenticate(application, result.accesstoken, chave);
    
    }).catch(async error => {
      console.log(error);
      if (error.error && Array.isArray(error.error)) {
        if (error.error == 'User must change password') {
          this.notification.info(await this.i18n.searchTranslation('atencao'), await this.apiErros.translateError(error.error[0]));
        }
        this.notification.error(await this.i18n.searchTranslation('erro'), await this.apiErros.translateError(error.error[0]));
      } else {
        this.notification.error(await this.i18n.searchTranslation('erro'),
          await this.i18n.searchTranslation('erroInesperado'));
      }
    });
  }

  async GoToSSOUrl(application: string, organization: string) {
    const result = await this.login.GetSSOURL(application, organization).then((result:string)=>{
      if(result!=='' && result!==undefined)
        this.window.location.href = result;
    });

  }
}
