<nav class="navbar navbar-default fixed-top bg-dark flex-md-nowrap p-0" >
  <a class="navbar-brand col-sm-3 col-md-2 mr-0 text-secondary d-flex align-items-center" > <!-- [routerLink]="['/home']"> -->
    <div class="navbar-header">
      <fa-icon [icon]="faMenu" (click)="toggleSidebar()" title="Expandir Menu" class="icon-custom"></fa-icon>
    </div>
    <span class="text-white bg-dark ml-2">
        <img style="max-width: 600px; max-height: 40px;" [src]="getMainLogoByOrg()" [routerLink]="['/home']" *ngIf="!isMonitor()" alt=""/>
        <img style="max-width: 600px; max-height: 40px;" [src]="getMainLogoByOrg()" *ngIf="isMonitor()" alt=""/>
      </span>
    <span class="ml-3">{{ environment }}</span>
  </a>
  <ul class="nav navbar-top-links navbar-right">
    <div class="btn-group mr-3 ml-4 mt-1">
      <select [disabled]="!isAdmin() || isVisitor()" class="custom-select-sm mr-2" id="selectOrg" style="width: 225px;"
        (change)="changeOrganization($event.target.value)">
        <option value="">{{ 'selecioneOrganizacao' | translate }}...</option>
        <option *ngFor="let organization of organizations" [value]="organization.idOrganization"
          [attr.selected]="selectedOrganization && selectedOrganization.idOrganization == organization.idOrganization ? 'selected' : null">
          {{ organization.organizationName }}
        </option>
      </select>
    </div>
    <div class="pipe-divider">&nbsp;</div>
    <div class="btn-group mr-3 ml-4 mt-1" >
      <select class="custom-select-sm mr-2" id="selectOrg" style="width: 225px;"
        (change)="changeInstance($event.target.value)" [disabled]="isVisitor()">
        <option value="">{{ 'selecioneInstancia' | translate }}...</option>
        <option *ngFor="let instance of selectedOrganization?.instances" [value]="instance.idInstance"
          [attr.selected]="selectedInstance && selectedInstance.idInstance == instance.idInstance ? 'selected' : null">
          {{ instance.instanceName }}
        </option>
      </select>
    </div>
    <div class="pipe-divider">&nbsp;</div>
    <div class="nav-item my-auto ml-1 mr-1" #myLangDrop="ngbDropdown" ngbDropdown autoClose="outside" display="dynamic">
      <button class="text-white bg-dark btn" id="dropdownLang" ngbDropdownToggle>
        <fa-icon [icon]="faGlobeAmericas" class="fa-lg mr-1"></fa-icon> {{ 'idioma' | translate }}
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownLang">
        <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('pt', myLangDrop)">
          <img width="26px" height="16px" src="assets/flags/brazil.png" alt="">
          <span class="align-middle ml-2">Português</span>
        </button>
        <hr class="my-1 p-0">
        <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('en', myLangDrop)">
          <img width="26px" height="16px" src="assets/flags/usa.png" alt="">
          <span class="align-middle ml-2">English</span>
        </button>
      </div>
    </div>
    <div class="pipe-divider">&nbsp;</div>
    <div class="d-inline-block col-sm-3 col-md-2" #dropUser="ngbDropdown" ngbDropdown autoClose="outside"
      display="dynamic">
      <button class="text-white bg-dark btn" id="dropDownUser" ngbDropdownToggle>
        {{ 'ola' | translate }}, <strong>{{ user.name }}</strong>
      </button>
      <div ngbDropdownMenu aria-labelledby="dropdownManual" >
        <button ngbDropdownItem class="pl-3 pr-3" (click)="changePassword()" *ngIf="user.authType==='DB'">
          <fa-icon [icon]="faUser" class="mr-1"></fa-icon> {{ 'alterarSenha' | translate }}
        </button>
        <hr class="my-1 p-0">
        <button ngbDropdownItem class="pl-3 pr-3" (click)="logout()">
          <fa-icon [icon]="faSignOutAlt" class="mr-1"></fa-icon> {{ 'sair' | translate }}
        </button>
      </div>
    </div>
  </ul>
  <div class="navbar-top-links-sm container row">
    <div class="col-2">
      <div class="nav-item my-auto mr-3" #myLangDrop="ngbDropdown" ngbDropdown autoClose="outside" display="dynamic">
        <button class="text-white bg-dark btn" id="dropdownLang" ngbDropdownToggle>
          <fa-icon [icon]="faGlobeAmericas" class="fa-lg mr-1"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownLang">
          <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('pt', myLangDrop)">
            <img width="26px" height="16px" src="assets/flags/brazil.png" alt="">
            <span class="align-middle ml-2">Português</span>
          </button>
          <hr class="my-1 p-0">
          <button ngbDropdownItem class="pl-3 pr-3" (click)="changeLanguage('en', myLangDrop)">
            <img width="26px" height="16px" src="assets/flags/usa.png" alt="">
            <span class="align-middle ml-2">English</span>
          </button>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="d-inline-block col-sm-3 col-md-2 float-right" #dropUser="ngbDropdown" ngbDropdown autoClose="outside"
        display="dynamic">
        <button class="text-white bg-dark btn" id="dropDownUser" ngbDropdownToggle>
          {{ 'ola' | translate }}, <strong>{{ user.name }}</strong>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropdownManual">
          <button ngbDropdownItem class="pl-3 pr-3" (click)="changePassword()">
            <fa-icon [icon]="faUser" class="mr-1"></fa-icon> {{ 'alterarSenha' | translate }}
          </button>
          <hr class="my-1 p-0">
          <button ngbDropdownItem class="pl-3 pr-3" (click)="logout()">
            <fa-icon [icon]="faSignOutAlt" class="mr-1"></fa-icon> {{ 'sair' | translate }}
          </button>
        </div>
      </div>
    </div>
    <div class="col-3">
      <div class="d-inline-block col-sm-3 col-md-2" ngbDropdown #dropMenu="ngbDropdown" display="dynamic">
        <button class="text-white bg-dark btn mt-2" ngbDropdownAnchor
          (click)="$event.stopPropagation(); dropMenu.open();">
          <fa-icon [icon]="faBars" style="font-size: 20px;"></fa-icon>
        </button>
        <div ngbDropdownMenu aria-labelledby="dropDownMenu" *ngIf="!isMonitor()">
          <div *ngFor="let item of navItems">
            <a ngbDropdownItem id="nav-link" class="nav-link" [routerLink]="item.route" routerLinkActive="active"
              *ngIf="((!item.children || item.children.length == 0) || (item.systemname != '' && item.systemname == systemName)) && hasAdminAccess(item)">
              <fa-icon [icon]="item.icon"></fa-icon>
              <span class="ml-2">{{ item.name }}</span>
            </a>
            <a class="nav-link" (click)="item.collapse = !item.collapse" id="nav-link"
              [attr.aria-expanded]="item.collapse"
              *ngIf="(item.children && item.children.length > 0) && hasAdminAccess(item)">
              <fa-icon [icon]="item.icon"></fa-icon>
              <span class="ml-2">{{ item.name }}</span>
              <fa-icon [icon]="faArrowLeft" *ngIf="item.collapse" class="float-right"></fa-icon>
              <fa-icon [icon]="faArrowDown" *ngIf="!item.collapse" class="float-right"></fa-icon>
            </a>
            <div id="collapseExample" [ngbCollapse]="item.collapse">
              <div *ngFor="let child of item.children">
                <a *ngIf="hasAdminAccess(child)" ngbDropdownItem id="nav-link" class="nav-link ml-2"
                  [routerLink]="child.route" routerLinkActive="active">
                  <fa-icon [icon]="child.icon"></fa-icon>
                  <span class="ml-2">{{ child.name }}</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-4">
      <div class="btn-group mt-2">
        <select style="width: 160px" class="custom-select-sm" id="selectOrg"
          (change)="changeOrganization($event.target.value)">
          <option value="">{{ 'selecioneOrganizacao' | translate }}...</option>
          <option *ngFor="let organization of organizations" [value]="organization.idOrganization"
            [attr.selected]="selectedOrganization && selectedOrganization.idOrganization == organization.idOrganization ? 'selected' : null">
            {{ organization.organizationName }}
          </option>
        </select>
      </div>
    </div>
    <div class="col-6">
      <div class="btn-group mt-2">
        <select style="width: 160px" class="custom-select-sm" id="selectOrg"
          (change)="changeOrganization($event.target.value)" [disabled]="isVisitor()">
          <option value="">{{ 'selecioneInstancia' | translate }}...</option>
          <option *ngFor="let organization of organizations" [value]="organization.idOrganization"
            [attr.selected]="selectedOrganization && selectedOrganization.idOrganization == organization.idOrganization ? 'selected' : null">
            {{ organization.organizationName }}
          </option>
        </select>
      </div>
    </div>
  </div>
</nav>

<div class="container-fluid" >
  <nav class="sidebar navbar-collapse" [ngClass]="{'sidebar-hidden': isSidebarHidden, 'sidebar-visible': !isSidebarHidden}" >
    <div class="sidebar-sticky">
      <ul class="nav flex-column">
        <li *ngFor="let item of navItems" class="nav-item" [ngClass]="{'dropdown': item.children && item.children.length > 0}">
          <a *ngIf="(!item.children || item.children.length == 0) && hasAdminAccess(item)"
             ngbDropdownItem class="nav-link text-white" [routerLink]="item.route" routerLinkActive="active">
            <fa-icon [icon]="item.icon"></fa-icon>
            <span class="ml-2">{{ item.name }}</span>
          </a>
          <a *ngIf="(item.children && item.children.length > 0) && hasAdminAccess(item)"
             class="nav-link text-white" (click)="item.collapse = !item.collapse" style="cursor: pointer;">
            <fa-icon [icon]="item.icon"></fa-icon>
            <span class="ml-2">{{ item.name }}</span>
            <fa-icon [icon]="faArrowLeft" *ngIf="item.collapse" class="float-right"></fa-icon>
            <fa-icon [icon]="faArrowDown" *ngIf="!item.collapse" class="float-right"></fa-icon>
          </a>
          <div id="collapseExample" [ngbCollapse]="item.collapse">
            <div *ngFor="let child of item.children">
              <a ngbDropdownItem class="nav-link text-white ml-2" *ngIf="hasAdminAccess(child)"
                 [routerLink]="child.route" routerLinkActive="active">
                <fa-icon [icon]="child.icon"></fa-icon>
                <span class="ml-2">{{ child.name }}</span>
              </a>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </nav>
  <main id="main" role="main" class="mb-1" style="height: 100%;">
    <app-loading [statusLoading]></app-loading>
    <p-breadcrumb [model]="items" [home]="home" *ngIf="!isMonitor()"></p-breadcrumb>
    <div id="page-wrapper">
      <div class="mt-3 ml-3 pr-3">
        <router-outlet></router-outlet>
      </div>
    </div>
    <div class="push"></div>
  </main>
  <footer class="footer">
    <div class="text-white" style="padding-top: 0.75rem; padding-right: 1rem;">
      <img class="float-right" src="assets/logo-taggen.png" alt="Powered by Taggen" width="78px">
      <span class="float-right">Powered by</span>
    </div>
  </footer>
</div>

<dialog id="logoffDialog">
  <p>{{'TemCertezaQueDesejaFinalizarSessao' | translate}}</p>
  <button id="confirmButton">{{'sim' | translate}}</button>
  <button id="cancelButton">{{'nao' | translate}}</button>
</dialog>