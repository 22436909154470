import { UserOrganizationModel } from './organization.model';

export class UserModel {
  id: number;
  name: string;
  email: string;
  idProfile: number;
  lastSeen: Date;
}

export class AddUserModel {
  name: string;
  email: string;
  idProfile: number;
  idOrganization: number;
}

export class EditUserModel {
  id: number;
  name: string;
  email: string;
  idProfile: number;
  idOrganization: number;
}

export class UserAndSuperModel {
  users: UserModel[];
  superUser: UserModel;
}

export class UserPaginationModel {
  data: UserAndSuperModel;
  total: number;
}

export class UserLoginModel {
  id: number;
  name: string;
  email: string;
  profile: string;
  idProfile: number;
  authType: string;
}

export class UserProfileModel {
  id: number;
  description: string;
}

export const UsersMock: UserModel[] = [
  // {
  //     id: 1,
  //     name: 'Luiz',
  //     email: 'luiz.santana@taggen.com.br',
  //     idProfile: 1,
  //     profile: 'Admin',
  //     idOrganization: 2
  // }
];

export const UserOrganizationMock: UserOrganizationModel[] = [
  {
    id: 10,
    idUser: 1,
    idOrganization: 1
  },
  {
    id: 11,
    idUser: 1,
    idOrganization: 2
  }
];

export const UserProfiles: UserProfileModel[] = [
  {
    id: 1,
    description: 'Admin'
  },
  {
    id: 2,
    description: 'User'
  },
  {
    id: 3,
    description: 'SuperUser'
  }
  ,
  {
    id: 4,
    description: 'Visitor'
  },
  {
    id: 5,
    description: 'Monitor'
  }
];
