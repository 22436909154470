<div class="loadingio-spinner-spinner-rdyrcnk7klb" *ngIf="status | async" id="loading">
    <div class="vertical-center" style="height: 100%;">
        <div class="ldio-yuim6o2b92" style="margin-bottom: 7%;">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
</div>