import { NgModule } from "@angular/core";
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ButtonsModule } from 'ngx-bootstrap/buttons';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginatorModule } from 'primeng/paginator';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { TreeTableModule } from 'primeng/treetable';
import { TableModule } from 'primeng/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { ToolbarModule } from 'primeng/toolbar';
import { DropdownModule } from 'primeng/dropdown';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { NgxMaskModule } from 'ngx-mask';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { DeleteDirective, DeleteModalComponent, DeleteService, DeleteState } from './services/delete-modal.service';
import { LoadingComponent } from './loading/loading.component';
import { NotificationService } from './services/notification.service';
import { StorageService } from './services/storage.service';
import { AuthGuard } from './services/auth-guard.service';
import { AuthGuardUser } from './services/auth-guard-user.service';
import { ValidateTokenService } from './services/validate-token.service';
import { I18nService } from './services/i18n.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TabViewModule } from 'primeng/tabview';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { Interceptor } from './services/interceptor.service';
import { PaginationComponent } from './services/pagination.service';
import { EnvironmentService } from './services/environment.service';
import { CalendarModule } from 'primeng/calendar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { CheckboxModule } from 'primeng/checkbox';
import { ChartModule } from 'primeng/chart';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDropdownModule,
    ButtonsModule.forRoot(),
    TabsModule.forRoot(),
    PaginatorModule,
    BreadcrumbModule,
    TreeTableModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    DropdownModule,
    TooltipModule,
    ButtonModule,
    NgxMaskModule.forRoot(),
    FontAwesomeModule,
    NgbModule,
    NgbDropdownModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      },
      isolate: false
    }),
    ConfirmDialogModule,
    ToolbarModule,
    TabViewModule,
    AutoCompleteModule,
    CalendarModule,
    RadioButtonModule,
    OverlayPanelModule,
    CheckboxModule,
    ChartModule
  ],
  declarations: [
    DeleteDirective,
    DeleteModalComponent,
    LoadingComponent,
    PaginationComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BsDropdownModule,
    ButtonsModule,
    TabsModule,
    PaginatorModule,
    BreadcrumbModule,
    TreeTableModule,
    TableModule,
    MultiSelectModule,
    DialogModule,
    DropdownModule,
    TooltipModule,
    ButtonModule,
    NgxMaskModule,
    DeleteDirective,
    DeleteModalComponent,
    LoadingComponent,
    FontAwesomeModule,
    NgbDropdownModule,
    TranslateModule,
    NgbModule,
    ConfirmDialogModule,
    ToolbarModule,
    TabViewModule,
    AutoCompleteModule,
    PaginationComponent,
    CalendarModule,
    RadioButtonModule,
    OverlayPanelModule,
    CheckboxModule,
    ChartModule
  ],
  providers: [
    DeleteService,
    DeleteState,
    NotificationService,
    StorageService,
    AuthGuard,
    AuthGuardUser,
    ValidateTokenService,
    I18nService,
    ConfirmationService,
    httpInterceptorProviders,
    DatePipe,
    EnvironmentService
    // ApiErrorsTranslateService
  ],
  entryComponents: []
})
export class SharedModule { }
