import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class PluralWordService {
  private rules = {
    add: {
      's': ['a', 'e', 'i', 'o', 'u', 'ã', 'ãe', 'ê'],
      'es': ['r', 'z', 'n', 'ás'],
      '': ['is', 'us', 'os']
    },
    replace: {
      'ais': 'al',
      'eis': 'el',
      'ois': 'ol',
      'uis': 'ul',
      'is': 'il',
      'ns': 'm',
      'eses': 'ês',
      'ões': 'ão',
      's': 's'
    },
    exceptions: {
      'males': 'mal',
      'cônsules': 'cônsul',
      'méis': 'mel',
      'féis': 'fel',
      'cais': 'cal',
      'avais': 'aval',
      'moles': 'mol',
      'racks': 'rack',
      'tags': 'tag',
      'partnumbers': 'partnumber',
    },
    no_plural: [
      'não'
    ],
  };

  plural(word: string): string {
    return this.transformWord(word);
  }

  private transformWord(word: string): string {
    var regex_change = "^([a-zA-Zà-úÀ-Ú]*)(%s)$";
    var plural = "";
    for (var rule in this.rules) {
      switch (rule) {
        case 'add':
          for (var add in this.rules[rule]) {
            var search = regex_change.replace("%s", this.rules[rule][add].join("|"));
            var regex = new RegExp(search, 'i');
            if (regex.exec(word) !== null) {
              plural = word + add;
              break;
            }
          }
          break;
        case 'replace':
          for (var replace in this.rules[rule]) {
            var search = regex_change.replace("%s", this.rules[rule][replace]);
            var regex = new RegExp(search, 'i');
            if (regex.exec(word) !== null) {
              if (word.match(/([áéíóú])/) !== null && regex.exec(word)[2] == "il") {
                plural = word.replace("il", "eis");
                break;
              } else {
                var search_sub = new RegExp(regex.exec(word)[2] + '$', 'i');
                plural = word.replace(search_sub, replace);
                break;
              }
            }
          }
          break;
        case 'exceptions':
          for (var exception in this.rules[rule]) {
            if (word.toLowerCase() == this.rules[rule][exception].toLowerCase()) {
              plural = exception;
              break;
            }
          }
          break;
        case 'no_plural':
          this.rules[rule].forEach((r) => {
            if (word.toLowerCase() === r) plural = word;
          });
          break;
      }
    }
    return plural.charAt(0).toUpperCase() + plural.slice(1) ?? word.charAt(0).toUpperCase() + word.slice(1);
  }
}
