import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './storage.service';
import { environment } from '../../../environments/environment';
import { ModalService } from './modal.service';

@Injectable()

export class ValidateTokenService {
    constructor(
        private storage: StorageService,
        private router: Router,
        private http: HttpClient,
        private modalService: ModalService
    ) { }

    async validateToken(reqUrl) {
        if (this.storage.getStorage().token) {
            let refreshTokenUrl = environment.apiUrl + '/Sign';
            const exp = this.storage.getExpiresDate();
            const now = new Date();
            const nowAdded = new Date(now.setMinutes(now.getMinutes() + 10));

            if (exp < now) {
                this.modalService.closeModal();
                this.storage.clearStorage();
                this.router.navigate(['/login']);
                return;
            }
            // else if (nowAdded > exp) {
            //     if (reqUrl !== refreshTokenUrl) {
            //         const REFRESH_DATA = {
            //             refreshToken: this.storage.getStorage().refreshToken,
            //             grantType: 'refresh_token'
            //         };
            //         await this.http.post(refreshTokenUrl, REFRESH_DATA).pipe(take(1)).toPromise().then(
            //             (data: LoginResult) => {
            //                 this.storage.refreshStorage(data.access_token, data.refresh_token, data.environment);
            //             }).catch(error => {
            //                 console.log(error);
            //             });
            //     }
            // }
        } else {
            this.modalService.closeModal();
            this.storage.clearStorage();
            this.router.navigate(['/login']);
        }
    }
}
