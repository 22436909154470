import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first } from 'rxjs/operators';
import { LoginModel, SendVerificationCodeModel, ChangePasswordModel } from '../../shared/models/login.models';
import { environment } from '../../../environments/environment';

@Injectable()
export class LoginService {
    
    constructor(
        private http: HttpClient
    ) { }

    authenticate(request: LoginModel) {
        return this.http.post(environment.apiUrl + 'SignIn', request).pipe(first()).toPromise();
    }

    async authenticatesso(application: string,code: string, chave: string) {
     
        return this.http.post(environment.apiUrl + 'SignIn/Authenticate/SSO' , {application: application, organization:chave, idtoken:code} ).pipe(first()).toPromise();
    }

    async identifysso(application: string,code: string, chave: string) {
        var ret = await this.http.get(environment.ssoUrl + 'Access/validate/' + application + '/' + chave + '/'+ code).pipe(first()).toPromise().then((result:string)=>{
            return result;
        });
        return ret;
    }

    
    HeartBeat() {
        return this.http.get(environment.apiUrl + `User/GetLogged`).pipe(first()).toPromise();
    }

    Logoff() {
        return this.http.get(environment.apiUrl + `User/LoggOff`).pipe(first()).toPromise();
      }
  

    forgotPassword(request: SendVerificationCodeModel) {
        return this.http.post(environment.apiUrl + 'User/ForgotPassword', request).pipe(first()).toPromise();
    }

    changePassword(request: ChangePasswordModel) {
        return this.http.post(environment.apiUrl + 'User/ChangePassword', request).pipe(first()).toPromise();
    }

    async GetSSOURL(application: string, organization: string)  : Promise<string> {
        var ret = await this.http.get(environment.ssoUrl + 'Access/' + application + '/' + organization, { responseType: 'text' }).pipe(first()).toPromise().then((result:string)=>{
            return result;
        });
        return ret;
    }
}
