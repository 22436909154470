import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { NavModel } from '../shared/models/nav.model';
import { faUser, faSignOutAlt, faHome, faGlobeAmericas, faLocationArrow, faBriefcase, faHdd, faArrowAltCircleDown, faArrowAltCircleLeft, faArchive, faMapSigns, faUsers, faBookmark, faSatelliteDish, faBars, faArrowsAlt, faHistory, faPen, faChartLine, faObjectGroup, faChartPie, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { I18nService } from '../shared/services/i18n.service';
import { UserOrganizationsLoginModel, InstanceModel, OrganizationLabelsModel, OrganizationLogoModel } from '../shared/models/organization.model';
import { StorageService } from '../shared/services/storage.service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';
import { UserLoginModel, UserProfiles } from '../shared/models/user.model';
import { AppService } from '../app.service';
import { EnvironmentService } from '../shared/services/environment.service';
import { UserProfileEnum } from '../shared/enums/user-profile.enum';
import { PluralWordService } from '../shared/services/plural-word.service';
import { LogoParamEnum } from '../shared/enums/logo-param.enum';
import { Title } from '@angular/platform-browser';
import { ImageSearchService } from '../shared/services/image-search.service';
import { LoginService } from '../views/login/login.service';
import { WINDOW } from '../window_provider';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {

  static readonly ROUTE_DATA_BREADCRUMB = 'title';
  readonly home = { icon: 'pi pi-home', url: '#/home' };
  user: UserLoginModel;
  navItems: NavModel[];
  faMenu = faBars;
  faUser = faUser;
  faSignOutAlt = faSignOutAlt;
  faGlobeAmericas = faGlobeAmericas;
  faArrowDown = faArrowAltCircleDown;
  faArrowLeft = faArrowAltCircleLeft;
  faArchive = faArchive;
  satelliteDish = faSatelliteDish;
  faChartLine = faChartLine;
  language: string;
  organizations: UserOrganizationsLoginModel[] = [];
  selectedOrganization: UserOrganizationsLoginModel = new UserOrganizationsLoginModel;
  items: any;
  faBars = faBars;
  userProfiles = UserProfiles;
  selectedInstance: InstanceModel = new InstanceModel();
  labels: OrganizationLabelsModel[];
  image: OrganizationLogoModel;
  appLogo: string;
  IsTGAgro: boolean;
  systemName: string;

  isSidebarHidden = false;

  constructor(
    private i18n: I18nService,
    private title: Title,
    private storage: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private appService: AppService,
    private imageSearchService: ImageSearchService,
    private environmentService: EnvironmentService,
    private pluralWordService: PluralWordService,
    private renderer: Renderer2,
    private login: LoginService,
    @Inject(WINDOW) private window: Window
  ) { }

  async ngOnInit() {
    this.user = this.storage.getStorage().user;

    
    if(this.isVisitor() || this.isMonitor())
    {
      this.toggleSidebar();
    }
    
    await this.getImage();
    await this.getEnvironment();
    this.initOrganizations();
    this.labels = this.storage.getSelectedOrganization()?.labels?.filter(x => x.labelKey != 'BEACON_ASSOCIATION');
    var organization =  this.storage.getSelectedOrganization();

    if (organization)
    {
      this.IsTGAgro = organization.systemName.toLowerCase() == "tgagro";
      this.systemName = organization.systemName.toLowerCase();
    }

    await this.initNavItems();
    this.language = this.i18n.language.value;

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(async () => this.items = await this.createBreadcrumbs(this.activatedRoute.root));
    this.items = await this.createBreadcrumbs(this.activatedRoute.root);
  }


  toggleSidebar() {
    this.isSidebarHidden = !this.isSidebarHidden;

    if(this.isVisitor() || this.isMonitor())
    {
      this.isSidebarHidden= true;
    }

    const mainElement = document.getElementById('main');
    if (this.isSidebarHidden) {
      this.renderer.addClass(mainElement, 'main-expanded');
    } else {
      this.renderer.removeClass(mainElement, 'main-expanded');
    }
  }

  private async createBreadcrumbs(route: ActivatedRoute, url: string = '#', breadcrumbs: MenuItem[] = []): Promise<MenuItem[]> {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }
    for (const child of children) {
      const routeURL: string = child.snapshot.url.map(segment => segment.path).join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      var label = child.snapshot.data[NavComponent.ROUTE_DATA_BREADCRUMB];
      if (label != null && label != undefined) {
        if (label == 'beaconAssociacao') {
          var beaconAssociation = this.storage.getSelectedOrganization()?.labels?.find(x => x.labelKey == 'BEACON_ASSOCIATION')?.labelValue;
          if (beaconAssociation) beaconAssociation = this.pluralWordService.plural(beaconAssociation);
          label = !!(beaconAssociation && beaconAssociation != '') ? beaconAssociation : await this.i18n.searchTranslation('beaconAssociacao');
        } else if (label == 'beaconsMonitorados') {
          var beaconAssociation = this.storage.getSelectedOrganization()?.labels?.find(x => x.labelKey == 'BEACON_ASSOCIATION')?.labelValue;
          if (beaconAssociation) beaconAssociation = this.pluralWordService.plural(beaconAssociation);
          label = !!(beaconAssociation && beaconAssociation != '') ? `${beaconAssociation} ${await this.i18n.searchTranslation('monitoradosNav')}` : await this.i18n.searchTranslation(label);
        } else {
          label = await this.i18n.searchTranslation(label);
        }
        breadcrumbs.push({ label, url });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }

  async initNavItems() {
    var beaconAssociation = this.storage.getSelectedOrganization()?.labels?.find(x => x.labelKey == 'BEACON_ASSOCIATION')?.labelValue;
    if (beaconAssociation) beaconAssociation = this.pluralWordService.plural(beaconAssociation);

    this.navItems = [];

    this.navItems.push( {
      name: await this.i18n.searchTranslation('inicio'),
      route: '/home',
      icon: faHome,
      administration: false,
      systemname: ''
    });

    this.navItems.push(  {
        name: await this.i18n.searchTranslation('dashboards'),
        route: '/dashboard',
        icon: faChartLine,
        administration: false,
        systemname: ''
      });

    if (this.systemName == "tgauto")
      this.navItems.push(
      {
        name: await this.i18n.searchTranslation('dashboards'),
        route: '/bi-dashboard',
        icon: faChartPie,
        administration: false,
        systemname: 'tgauto'
      });

    this.navItems.push({
        name: await this.i18n.searchTranslation('movimentacoes'),
        route: '/tracking',
        icon: faArrowsAlt,
        administration: false,
        systemname: ''
      });

    this.navItems.push({
        name: !!(beaconAssociation && beaconAssociation != '') ? `${beaconAssociation} ${await this.i18n.searchTranslation('monitoradosNav')}` :
          await this.i18n.searchTranslation('beaconsMonitorados'),
        route: '/monitored-prisms',
        icon: faBookmark,
        administration: false,
        systemname: ''
      });

    if (this.storage.getSelectedOrganization()?.organizationName?.toLowerCase().startsWith('raizen'))
      this.navItems.push({
          name: await this.i18n.searchTranslation('exchangeSets'),
          route: '/exchange-sets',
          icon: faExchangeAlt,
          administration: false,
          systemname: ''
        });

    this.navItems.push(
      {
        name: await this.i18n.searchTranslation('localizacao'),
        route: '/location',
        icon: faLocationArrow,
        administration: false,
        systemname: ''
      });

    var cadastros = {
      name: await this.i18n.searchTranslation('cadastros'),
      route: 'add',
      icon: faArchive,
      administration: true,
      collapse: true,
      children: [
        {
          name: await this.i18n.searchTranslation('usuarios'),
          route: '/users',
          icon: faUsers,
          administration: true,
          systemname: ''
        },
        {
          name: !!(beaconAssociation && beaconAssociation != '') ? beaconAssociation : await this.i18n.searchTranslation('beaconAssociacao'),
          route: '/prisms',
          icon: faSatelliteDish,
          administration: false,
          systemname: ''
        },
        {
          name: await this.i18n.searchTranslation('locais'),
          route: '/places',
          icon: faMapSigns,
          administration: false,
          systemname: ''
        },
        (this.IsTGAgro ?
          {
          name: await this.i18n.searchTranslation('conjuntos'),
          route: '/groups',
          icon: faObjectGroup,
          administration: true,
          systemname: ''
          }
        : null)
      ],
      systemname: ''
    };
    this.navItems.push(cadastros);

    var administracao = {
      name: await this.i18n.searchTranslation('administracao'),
      route: 'adm',
      icon: faBriefcase,
      administration: true,
      collapse: true,
      children: [
        {
          name: await this.i18n.searchTranslation('gateways'),
          route: '/gateways',
          icon: faHdd,
          administration: true,
          systemname: ''
        },
        {
          name: await this.i18n.searchTranslation('auditoria'),
          route: '/audit',
          icon: faHistory,
          administration: true,
          systemname: ''
        },
        {
          name: await this.i18n.searchTranslation('organizacaoCampos'),
          route: '/organization-labels',
          icon: faPen,
          administration: true,
          systemname: ''
        }
      ],
      systemname: ''
    };
    this.navItems.push(administracao);

    if (!this.selectedOrganization || !this.selectedOrganization.idOrganization)
      this.navItems = this.navItems.filter(n => n.route == '/home');

    if (this.selectedOrganization && this.selectedOrganization.idOrganization && this.isEuroMotorsUser())
      this.navItems = this.navItems.filter(n => n.route != '/tracking');

    if (this.selectedOrganization && this.selectedOrganization.idOrganization && !this.isAdmin() && !this.isDowUser() && this.systemName != "tgagro")
      this.navItems = this.navItems.filter(n => n.route != '/dashboard');

    if (this.selectedOrganization && this.selectedOrganization.idOrganization && !this.hasColumnKey()) {
      this.navItems = this.navItems.filter(n => n.route == 'adm' || n.route == '/home');
      this.navItems.forEach(x => {
        if (x.children && x.children.length > 0)
          x.children = x.children.filter(a => a.route == '/organization-labels');
      });
    }
  }

  initOrganizations() {
    this.organizations = this.storage.getStorage().organizations;
    if (this.organizations?.length == 1) {
      this.storage.setSelectedOrganization(this.organizations[0]);
    } else {
      this.selectedOrganization = new UserOrganizationsLoginModel();
    }
    this.selectedOrganization = this.storage.getSelectedOrganization() ?? new UserOrganizationsLoginModel();
    if (this.selectedOrganization?.instances?.length == 1) {
      this.storage.setSelectedInstance(this.selectedOrganization.instances[0]);
       // this.changeOrganization(this.selectedOrganization.idOrganization);
       // location.reload();
      this.getImage();
    }
    this.selectedInstance = this.storage.getSelectedInstance() ?? new InstanceModel();
  }

  async changeLanguage(language: string, myLangDrop: any) {
    this.i18n.changeLanguage(language);
    await this.initNavItems();
    this.language = this.i18n.language.value;
    myLangDrop.close();
    location.reload();
  }

  async changeOrganization(idOrganization: number) {
    await this.getImage();
    
    if(!this.isMonitor())
      this.router.navigate(['/home']);
    
    var organization = this.organizations.find(o => o.idOrganization == idOrganization);
    this.storage.setSelectedOrganization(organization);
    this.storage.clearSelectedInstance();
    this.selectedInstance = new InstanceModel();
    this.selectedOrganization = organization;
    location.reload();
  }

  changeInstance(idInstance: number) {
    var instance = this.selectedOrganization.instances.find(x => x.idInstance == idInstance);
    this.storage.setSelectedInstance(instance);
    this.selectedInstance = instance;
    location.reload();
  }

  async logout() {
    if (await this.confirmLogout()) {
      if(this.user.authType === undefined || this.user.authType ===null || this.user.authType==="DB")
      { 
        this.storage.clearStorage();
        this.router.navigate(['/']);
        window.location.reload();

      }
      else
      {
        this.login.Logoff().then((result:string)=>{
          if(result!=='' && result!==undefined)
          
            this.storage.clearStorage();
            this.router.navigate(['/']);
            window.location.reload();
        });
      }  //alert("SSO");
    }
  }

  

  confirmLogout(): Promise<boolean> {
    return new Promise((resolve) => {
      const dialog = document.getElementById('logoffDialog') as HTMLDialogElement;
      const confirmButton = document.getElementById('confirmButton');
      const cancelButton = document.getElementById('cancelButton');

      const onConfirm = () => {
        dialog.close();
        confirmButton.removeEventListener('click', onConfirm);
        cancelButton.removeEventListener('click', onCancel);
        resolve(true);
      };

      const onCancel = () => {
        dialog.close();
        confirmButton.removeEventListener('click', onConfirm);
        cancelButton.removeEventListener('click', onCancel);
        resolve(false);
      };

      confirmButton.addEventListener('click', onConfirm);
      cancelButton.addEventListener('click', onCancel);

      dialog.showModal();
    });
  }

  collapse(dropdown: string) {
    this.navItems.find(n => n.route == dropdown).collapse = !this.navItems.find(n => n.route == dropdown).collapse;
  }

  changePassword() {
    this.router.navigate(['/change-password']);
  }

  hasAdminAccess(item: NavModel): boolean {
    if (item)
      if (!item.administration || this.user.profile == this.userProfiles.find(u => u.id == UserProfileEnum.Admin).description || this.user.profile == this.userProfiles.find(u => u.id == UserProfileEnum.SuperUser).description)
        return true;
      else
        return false;
    else
        return false;
  }

  isEuroMotorsUser(): boolean {
    return this.appService.isEuroMotorsUser();
  }

  isDowUser(): boolean {
    return this.appService.isDowUser();
  }

  async getEnvironment() {
    var env = await this.environmentService.getEnvironment();
    this.storage.setEnvironment(env);
    // if (env != this.environment)
    //   this.logout();
  }

  get environment() {
    return this.storage.getStorage().env.toLowerCase() != 'Production'.toLowerCase() ?
      `[${this.storage.getStorage().env}]` : '';
  }

  isAdmin() {
    if (this.user.idProfile == UserProfileEnum.Admin)
      return true;
    else
      return false;
  }


  isSuperUser() {
    if (this.user.idProfile == UserProfileEnum.SuperUser)
      return true;
    else
      return false;
  }

  isVisitor() {
    if (this.user && this.user.idProfile == UserProfileEnum.Visitor) 
      return true;
    else 
      return false;
  }

  isMonitor() {
    if (this.user && this.user.idProfile == UserProfileEnum.Monitor) 
      return true;
    else 
      return false;
  }
  
  
  hasColumnKey() {
    if (this.labels && this.labels.find(x => x && x.isKeyColumn))
      return true;
    else
      return false;
  }

  async getImage() {
    // var idOrganization = this.storage.getSelectedOrganization()?.idOrganization;
    var idOrganization = this.selectedOrganization?.idOrganization;
    if (idOrganization) {
      await this.imageSearchService.getImage(idOrganization, LogoParamEnum.logoPrincipal).then((x: OrganizationLogoModel) => {
        if (x == null) {
          if (this.selectedOrganization.systemName?.toLowerCase() == "tgagro") {
            this.appLogo = 'assets/tg_agro_negativo.png';
          } else
          {
            this.appLogo = 'assets/tg_auto_negativo.png';
          }
        } else {
          this.image = x;
        }
      });
    } else {
      this.appLogo = 'assets/logo-taggen.png';
    }
  }

  getMainLogoByOrg() {
    if (this.image)
      return 'data:image/jpg;base64,' + this.image.image;
    else
    return this.appLogo;
  }
}
