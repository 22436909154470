import { OrganizationLabelsModule } from 'src/app/views/organization-labels/organization-labels.module';

// FROM LINK IOT DB
export class LinkIoTOrganizationModel {
  name: string;
  organizationKey: string;
  description: string;
  applications: LinkIoTApplicationModel[];
}

export class LinkIoTApplicationModel {
  name: string;
  description: string;
  authKey: string;
}

// FROM TG AUTO DB
export class UserOrganizationsLoginModel {
  idOrganization: number;
  instances: InstanceModel[];
  organizationName: string;
  systemName: string;
  labels: OrganizationLabelsModel[];
  logos: OrganizationLogoModel[];
}

export class OrganizationModel {
  id: number;
  name: string;
  authKey: string;
}

export class OrganizationPaginationModel {
  data: OrganizationModel[];
  total: number;
}

export class AddOrganizationModel {
  name: string;
  authKey: string;
}

export class UserOrganizationModel {
  id: number;
  idUser: number;
  idOrganization: number;
}

export class AddUserOrganizationModel {
  idUser: number;
  idsOrganizations: number[] = [];
  idOrganization: number;
}

export class InstanceModel {
  idInstance: number;
  instanceName: string;
}

export class OrganizationMaxUsersModel {
  id: number;
  name: string;
  superUser: string;
  idSuperUser: number;
  maxUsers: number;
  totalUsers: number;
  users: OrganizationUsersModel[];
}

export class OrganizationUsersModel {
  id: number;
  name: string;
  email: string;
  idProfile: number;
  profile: string;
}

export class OrganizationLabelsModel {
  labelKey: string;
  labelValue: string;
  labelType: string;
  labelLength: number;
  labelMask: string;
  isKeyColumn: boolean;
  id: number;
}

export class OrganizationLogoModel {
  image: any;
  imageName: string;
  imageIdentification: number;
}

export const OrganizationsMock: OrganizationModel[] = [
  {
    id: 1,
    name: 'Development test',
    authKey: '65ds465gf465d4g65df'
  },
  {
    id: 2,
    name: 'Development test 2',
    authKey: '154sd1gfdsgfd2sf13'
  },
  {
    id: 3,
    name: 'Development test 3',
    authKey: 'df4g654f56hdgdfg'
  },
  {
    id: 4,
    name: 'Development test 4',
    authKey: 'fdgsdf654g65sdf465'
  },
];

export const LinkIoTOrganizationsMock: LinkIoTOrganizationModel[] = [
  {
    name: 'Test 20',
    organizationKey: 'TEST20',
    description: '',
    applications: [
      {
        name: 'App 120',
        description: '',
        authKey: '64654564ergre'
      }
    ]
  }
];
