export const environment = {
  production: true,
  //apiUrl: 'https://localhost:59004/api/v1/',
  apiUrl: 'https://api.taggen.net/tgagro/api/v1/',
  //apiUrl: 'https://apidev.taggen.net/hml/tgagro/api/v1/',
  //ssoUrl: 'https://sso.hmlg.taggen.net/',
  //ssoUrl: 'https://localhost:50749/',
  ssoUrl: 'https://eszxl8tqm1.execute-api.sa-east-1.amazonaws.com/Prod/',
  keys: {
    token: 'TOKEN',
    refresh_token: 'REFRESH_TOKEN',
    expires: 'EXPIRES_DATE',
    authTime: 'AUTH_TIME',
    user: 'USER',
    env: 'ENVIRONMENT',
    userOrganizations: 'USER_ORGANIZATIONS',
    selectedOrganization: 'SELECTED_ORGANIZATION',
    selectedInstance: 'SELECTED_INSTANCE',
    userGrids: 'USER_GRIDS',
    language: 'LANGUAGE',
    trackingViewOption: 'TRACKING_VIEW_OPTION',
    cognito_domain : 'https://taggenhml.auth.sa-east-1.amazoncognito.com/',
    cognito_client_id : '1idmj1cap0lgopv9lbs0grtdll',
    cognito_redirect_uri: 'https://raizen.hmlg.taggen.net'
  },

  mapbox: {
    accessToken: 'pk.eyJ1Ijoiam9hb3BpcmVzIiwiYSI6ImNrdG9meHh6eDBjODEybnA5eW1vaTR2cjgifQ.e5VKmq8A4ZUxorSnvVvSvg',
    style: 'mapbox://styles/mapbox/streets-v11'
  }
};
