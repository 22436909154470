import { Injectable, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { StorageService } from './storage.service';

@Injectable()

export class I18nService implements OnInit {

  public language = new BehaviorSubject<string>(null);
  public languages: string[] = ['pt', 'en'];

  constructor(private translateService: TranslateService, private storage: StorageService) {
    this.translateService.setDefaultLang('pt');
    this.translateService.addLangs(this.languages);
    var lang = this.storage.getLanguage();
    this.language.next(lang);
    this.translateService.use(this.language.value);
  }

  ngOnInit(): void { }

  changeLanguage(language: string) {
    this.language.next(language);
    this.translateService.use(this.language.value);
    this.storage.setLanguage(language);
  }

  async searchTranslation(key: string): Promise<string> {
    let translation: string;
    await this.translateService.get(key).pipe(first()).toPromise().then(value => { translation = value; });
    if (translation == key) {
      return '';
    }
    return translation;
  }

}
