import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { first } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })

export class EnvironmentService {

  constructor(
    private http: HttpClient,
  ) { }

  async getEnvironment() {
    var env = '';
    await this.http.get(`${environment.apiUrl}Environment`).pipe(first()).toPromise().then((result: string) => {
      env = result;
    });
    return env;
  }
}
