import { Injectable, ElementRef } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Injectable({ providedIn: 'root' })
export class ModalService {
  closeResult: string;

  constructor(private ngbModal: NgbModal) { }

  public openSmallModal(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'sm' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openSmallModalWithoutBackdrop(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'sm', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openSmallModalWithoutBackdropCentered(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'sm', backdrop: false, centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  public openMediumModal(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'md' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openMediumModalWithoutBackdrop(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'md', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openMediumModalWithoutBackdropCentered(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'md', backdrop: false, centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openLargeModal(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openLargeModalWithoutBackdrop(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'lg', backdrop: false }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public openLargeModalWithoutBackdropCentered(modal: ElementRef) {
    this.ngbModal.open(modal, { ariaLabelledBy: 'modal', size: 'lg', backdrop: false, centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  public closeModal() {
    this.ngbModal.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
